@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-select-custom {
  @at-root #{&}__input.filled {
    &::placeholder {
      color: var(--primary-dark);
      font-family: $font-family-regular;
    }
  }
  @at-root #{&}__wrapper-icon {
    top: 0;
    right: 0;
  }
  @at-root #{&}__icon-chevron,
    #{&}__icon-remove {
    cursor: pointer;
    width: 35px;
    height: 48px;
    z-index: 9;
  }
  @at-root #{&}__wrapper-list {
    border: 2px solid var(--gray-0);
    z-index: 10;
  }
  @at-root #{&}__item {
    padding-left: 1rem;
    height: 56px;
    border-bottom: 2px solid var(--gray-0);
    .sz-checkbox {
      padding-left: 2.5rem;
      &.checked {
        background: var(--gray-0);
        &:hover {
          background: var(--gray-0);
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: var(--gray-100);
      }
    }
  }
}
